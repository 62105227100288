import React from 'react'

const About = () => {
    return (
        <section id="about" className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2 text-center">
                <div className="section-title">
                  <h1>modern <span className="alo">Digital platform builder</span></h1> 
                  <span className="border-line" />
                  <p className="lead subtitle-caption">
                    we are a digital platform builder located in <span className="colored-text">Lagos, Nigeria</span> We hope to fight poverty in Africa through digital platforms.
                  </p>
                </div>
              </div>
            </div>{/* title row end*/}
            <div className="row">
              <div className="col-sm-6 margin-bottom30">
                <div className="feature-icon-wrap clearfix">
                  <div className="left-side-icon">
                    <i className="ion-ios-lightbulb-outline front-icon" />
                    <i className="ion-ios-lightbulb-outline back-icon" />
                  </div>
                  <div className="features-text-right">
                    <h3>Innovative Solutions</h3>                         
                    <p>
                      There are a lot of problems in Nigeria, as well as Africa at large, and innovative digital solutions 
                      are needed to tackle them.
                    </p>
                  </div>
                </div>{/*features icon wrap*/}
              </div>{/*features col*/}
              <div className="col-sm-6 margin-bottom30">
                <div className="feature-icon-wrap clearfix">
                  <div className="left-side-icon">
                    <i className="ion-ios-gear-outline front-icon" />
                    <i className="ion-ios-gear-outline back-icon" />
                  </div>
                  <div className="features-text-right">
                    <h3>Customer centric products</h3>                         
                    <p>
                      Our platforms are customer-centric by incorparating a holostic approach to our platform development process, whilst
                      adding a local flavour to said platforms.
                    </p>
                  </div>
                </div>{/*features icon wrap*/}
              </div>{/*features col*/}
            </div>{/*row*/}
            <div className="row">
              <div className="col-sm-6 margin-bottom30">
                <div className="feature-icon-wrap clearfix">
                  <div className="left-side-icon">
                    <i className="ion-iphone front-icon" />
                    <i className="ion-iphone back-icon" />
                  </div>
                  <div className="features-text-right">
                    <h3>Omni-channel Platforms</h3>                         
                    <p>
                     Our platforms are available on web, Android, and iOS with consistent UX across all channels.
                    </p>
                  </div>
                </div>{/*features icon wrap*/}
              </div>{/*features col*/}
              <div className="col-sm-6 margin-bottom30">
                <div className="feature-icon-wrap clearfix">
                  <div className="left-side-icon">
                    <i className="ion-ios-people-outline front-icon" />
                    <i className="ion-ios-people-outline back-icon" />
                  </div>
                  <div className="features-text-right">
                    <h3>Dedicated Customer Support</h3>                         
                    <p>
                      Our platforms have multi-channel dedicated customer support.
                    </p>
                  </div>
                </div>{/*features icon wrap*/}
              </div>{/*features col*/}
            </div>{/*row*/}
          </div>{/*container*/}      
        </section>
    )
}

export default About

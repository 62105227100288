import React from 'react'

const Contact = () => {
    return (
        <>
        <div className="cta cta-bg">
        <div className="container text-center scroll-to">
          <h1>WE'VE GOT PLENTY MORE TO SAY</h1>
          <a href="#contact" className="btn btn-white btn-radius btn-lg">Let's talk</a>
        </div>
      </div>
    
      <section id="contact" className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="row contact-details">
                <div className="col-sm-6 margin-bottom30 text-center">
                  <i className="ion-ios-location-outline" />
                  <h4>Lagos, Nigeria</h4>
                </div>
                <div className="col-sm-6 margin-bottom30 text-center">
                  <i className="ion-ios-email-outline" />
                  <h4>support@athenatechworks.com</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    )
}

export default Contact
